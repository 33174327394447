<template functional>
    <div class="page container">
        
        <div class="columns">
            <div class="column">

                <p>OmniMundi GmbH &amp; Co. KG
                    <br/>
                    Geschäftsführerin Prof. Dr. Renate Girmes
                    <br/>
                    Schönebecker Straße 51<br/>
                    39104 Magdeburg
                </p>
                <p>
                    <br/><br/>
                    Kontakt:
                    <br/><br/>
                    <a href="kontakt@entfalter.de"><i class="jam jam-inbox"/>&nbsp; kontakt[at]entfalter.de</a><br/>
                    <a href="tel:0391/50380903"><i class="jam jam-smiley"/> &nbsp;0391/50380903</a><br/>
                    <a href="tel:0391/40063658"><i class="jam jam-smiley"/> &nbsp;0391/40063658</a>
                    <br/><br/>
                    USt-IdNr.: DE298694882
                    <br/><br/>
                    Handelsregister: HRA 4589
                    <br/><br/>
                    Gerichtsstand: Amtsgericht Magdeburg
                    <br/><br/>
                    <a href="tel:0391/50380903">0391/50380903</a>
                </p>

            </div>
        </div>
    </div>
</template>

<script>

	export default {
		name: 'legal'
	}
</script>


